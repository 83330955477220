import * as React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { Page } from '../containers/Page'


export default () => <Page>
    <Box p={8}>
        <Text fontSize="xl">Share page</Text>
    </Box>
</Page>
